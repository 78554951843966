import styled from "styled-components"

import { CommonParagraph } from "../../common/Texts"
import { ImageWithLinkOrNot as ImageWithLinkOrNotBase } from "../../common/ImageWithLinkOrNot"
import { Link as LinkBase } from "../../common/Link"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
`

export const Link = styled(LinkBase)`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 1em;
`

export const Name = styled(CommonParagraph)`
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0.2em;
`

export const PictoWrapper = styled.div`
  width: 100%;
  height: 3em;
  position: relative;
  top: 1.5em;
  img {
    object-fit: contain;
    z-index: 1;
    width: 3em;
    height: 3em;
  }
`

export const PictoLeft = styled(ImageWithLinkOrNotBase)`
  position: absolute;
  left: 1em;
`

export const PictoRight = styled(ImageWithLinkOrNotBase)`
  position: absolute;
  right: 1em;
`

export const ImageWithLinkOrNot = styled(ImageWithLinkOrNotBase)`
  height: 300px;
  object-fit: cover;
  &.mobile {
    height: 100%;
  }
`

export const Brand = styled(Name)`
  text-transform: uppercase;
  font-size: 1.3em;
  margin-bottom: 0.5em;
  color: #4a4a4a;
`

export const PriceDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
`

export const OriginalPrice = styled(CommonParagraph)`
  // font-size: 13px;
  color: #2b3944;
  margin: 0;
  height: 26px;
  display: flex;
  align-items: flex-end;
`

export const OnlyPrice = styled(CommonParagraph)`
  font-size: 17px;
  font-weight: bold;
  color: #c4ab88;
  margin: 0;
  display: flex;
  align-items: flex-end;
`

export const OldPrice = styled(OriginalPrice)`
  text-decoration: line-through;
  font-weight: normal;
  // font-size: 15px;
  color: #2b3944;
  display: flex;
  align-items: flex-end;
`

export const Prices = styled(PriceDetails)`
  flex-direction: column-reverse;
  gap: 0;
`

export const TileProduct = styled.div``
