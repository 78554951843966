import React from "react"
import { fixPrice } from "../../../helper/fixedPrice"
import getBrandFromSaleor from "../../../helper/getBrandFromSaleor"
import getBrandFromZoho from "../../../helper/getBrandFromZoho"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { getMetadata } from "../../../utils"
import { enumFrom } from "../ImageWithLinkOrNot"
import {
  Link,
  Name,
  PriceDetails,
  OldPrice,
  OriginalPrice,
  OnlyPrice,
  PictoRight,
  PictoWrapper,
  PictoLeft,
  Brand,
  ImageWithLinkOrNot,
  TileProduct,
  Prices,
} from "./ProductListItem.style"

var slugify = require("slugify")

interface Props {
  className?: string
  id: string
  name: string
  slug: string
  media: Record<string, any>
  pricing: Record<string, any>
  metadata: Record<string, string>
  thumbnail: Record<string, string>
  active: boolean
  data: Record<string, any>
  variantsMetadata: Record<string, any>
  products: any
  isBack: boolean
  brandName: string
}

export const getPricingNode = pricing => {
  let startingPrice = fixPrice(pricing?.priceRange?.start?.gross.amount)
  let startingPriceUndiscounted = fixPrice(
    pricing?.priceRangeUndiscounted?.start?.gross.amount
  )
  let currency = pricing?.priceRange?.start?.gross.currency
  if (Number(startingPrice) == 0) {
    return <></>
  }
  if (pricing.onSale) {
    return (
      <PriceDetails>
        <OriginalPrice>A partir de</OriginalPrice>
        <Prices>
          <OnlyPrice>
            {startingPrice}
            {currency === "Eur" ? "€" : currency}
          </OnlyPrice>
          <OldPrice>
            {startingPriceUndiscounted}
            {currency === "Eur" ? "€" : currency}
          </OldPrice>
        </Prices>
      </PriceDetails>
    )
  }
  if (currency == null) {
    return (
      <PriceDetails>
        <OriginalPrice>A partir de</OriginalPrice>
        <OnlyPrice> {startingPrice}€</OnlyPrice>
      </PriceDetails>
    )
  }
  return (
    <PriceDetails>
      <OriginalPrice>A partir de</OriginalPrice>
      <OnlyPrice>
        {startingPrice}
        {currency === "Eur" ? "€" : currency}
      </OnlyPrice>
    </PriceDetails>
  )
}

export function ProductListItemView({
  className,
  id,
  name,
  slug,
  media,
  thumbnail,
  pricing,
  metadata,
  active,
  data,
  brandName,
  variantsMetadata,
}: Props) {
  // TODO: enlever la metadata pour trouver la marque ...
  const meta = getMetadata(metadata)
  const isMobile = useDetectDevice()

  const link = "/p/" + brandName + "/" + slug
  const gmPicto = data.strapiGmPicto

  var isModel = false
  if (meta["is_model"] && meta["is_model"] == "true") isModel = true
  const isDiscount = () => {
    const result = variantsMetadata
      ?.map(x => x.metadata)
      .flat()
      .filter(k => k?.key == "zoho_promo")
      .filter(r => r.value != "")
    return result ? result[0]?.value : null
  }

  const isNew = () => {
    return variantsMetadata
      ?.map(x => x.metadata)
      .flat()
      .filter(k => k?.key == "zoho_is_new")
      .some(r => r.value == "true")
  }

  function productWithPicto() {
    if (isDiscount()) {
      var promo = "Promo" + isDiscount()
      if (gmPicto && gmPicto[promo]) {
        return <PictoRight data={gmPicto[promo]} from={enumFrom.STRAPI} />
      }
    }
    if (isNew()) {
      return <PictoLeft data={gmPicto?.Nouveaute} from={enumFrom.STRAPI} />
    }
  }

  return (
    <TileProduct id={slug}>
      <Link link={link} className={className}>
        <PictoWrapper>{productWithPicto()}</PictoWrapper>
        <ImageWithLinkOrNot
          className={isMobile && "mobile"}
          data={thumbnail}
          from={enumFrom.SALEOR}
        />
        <Brand className={isMobile && "mobile"}>{meta["zoho_brand"]}</Brand>
        <Name className={isMobile && "mobile"}>{name}</Name>
        <PriceDetails>{!isModel && getPricingNode(pricing)}</PriceDetails>
      </Link>
    </TileProduct>
  )
}
