import slugify from "slugify"

function getBrandFromSaleor(attributes: any[]): string {
  let brand = attributes.filter(({ attribute }) => {
    return attribute?.slug == "marque"
  })[0]

  return slugify(brand?.values[0]?.slug || "").toLowerCase()
}

export default getBrandFromSaleor
